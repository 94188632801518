import React from "react";
import {
	createStyles, Box, Grid, Theme, Divider, Hidden, Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
	RouteComponentProps, withRouter, Switch, Route, Redirect,
} from "react-router-dom";
import SideMenu from "../shared/SideMenu";
import AccountMenu from "../shared/AccountMenu";
import { RoutesUrl } from "../../common/consts/Routes";
import logo from "../../assets/logo.png";
import Exhibitions from "../Exhibitions/Exhibitions";
import ExhibitionsList from "../Exhibitions/ExhibitionsList";
import DataBases from "../DataBases/DataBases";
import DataBasesList from "../DataBases/DataBasesList";
import Servicing from "../Servicing/Servicing";
import ServicingList from "../Servicing/ServicingList";
import Contragents from "../Contragents/Contragents";
import ContragentsList from "../Contragents/ContragentsList";
import BusinessAnalysisList from "../BusinessAnalysis/BusinessAnalysisList";
import oneImg from "../../assets/1.png";
import twoImg from "../../assets/2.png";

const styles = ({ palette, breakpoints }: Theme) => {
	return createStyles({
		"@global": {
			"html, body, #root": {
				width: "100%",
				height: "100%",
			},
		},
		body: {
			height: "100%",
			backgroundColor: palette.background.default,
			[breakpoints.down("sm")]: {
				display: "block",
			},
			[breakpoints.up("sm")]: {
				"> *": {
					minHeight: "100%",
				},
			},
		},
		paper: {
			backgroundColor: palette.background.paper,
			padding: "1px",
			minWidth: 260,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		},
		content: {
			flex: 1,
			overflow: "auto",
		},
		default: {
			background: palette.background.default,
			padding: "1px 0px"
		}
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function Dashboard(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	return (
		<Grid container className={classes.body} wrap="nowrap">
			<Box className={`${classes.paper} noPrint`}>
				<Box>
					<Box className={classes.default}>
						<img src={logo} alt="logo" style={{
							height: 60,
							margin: "10px auto 20px auto",
							display: "block",
						}} />
					</Box>
					<AccountMenu />
					<Hidden smDown>
						<Box><Divider variant="fullWidth" color="primary" /></Box>
						<SideMenu />
					</Hidden>
				</Box>
				<Box padding={2} className={classes.info}>
					<Typography variant="body2">Produced by: MAXX IQ LTD.<br />
						Serial Number: 2023-PCK-01
					</Typography>
				</Box>
			</Box>
			<Box padding="17px 20px" overflow="auto" flex="auto">
				<Switch>
					<Route path={RoutesUrl.exhibitions.list} component={ExhibitionsList} />
					<Route path={RoutesUrl.exhibitions.create} component={Exhibitions} />
					<Route path={RoutesUrl.exhibitions.edit()} component={Exhibitions} />

					<Route path={RoutesUrl.dataBases.list} component={DataBasesList} />
					<Route path={RoutesUrl.businessAnalysis.list} component={BusinessAnalysisList} />
					<Route path={RoutesUrl.dataBases.create} component={DataBases} />
					<Route path={RoutesUrl.dataBases.edit()} component={DataBases} />

					<Route path={RoutesUrl.contragents.list} component={ContragentsList} />
					<Route path={RoutesUrl.contragents.create} component={Contragents} />
					<Route path={RoutesUrl.contragents.edit()} component={Contragents} />

					<Route path={RoutesUrl.servicing.list} component={ServicingList} />
					<Route path={RoutesUrl.servicing.create} component={Servicing} />
					<Route path={RoutesUrl.servicing.edit()} component={Servicing} />

					<Route path="*">
						<Redirect to={RoutesUrl.dataBases.list} />
					</Route>

				</Switch>
				<Box sx={{ fontSize: "16px", display: "flex", alignItems: "flex-end", marginTop: "50px", marginBottom: "30px" }}>
					<img src={oneImg} height="50px" width="auto" style={{ marginRight: "20px" }}></img>
					<Typography>Софтуерът е закупен със средства по договор БФП - BG-RRP-3.005-3485-C01 по процедура Решения в областта на информационните и комуникационни технологии и киберсигурността в малките и средните предприятия, финансиран по Програмата за икономическа трансформация към Националния план за възстановяване и устойчивост.</Typography>
					<img src={twoImg} height="100px" width="auto" style={{ marginLeft: "15px" }}></img>
				</Box>
			</Box>

		</Grid>
	);
}

export default withRouter(withStyles(styles)(Dashboard));
